<template>
  <div class="">
    <div class="p-0 m-0">
      <b-container>
        <b-card
          no-body
          class="mr-1"
          style=""
        >
          <div class="pl-1 pt-1 ">
            <span
              class="saj-title"
              style=""
            >
              {{ $t("Request List") }}
            </span>
          </div>
          <div class="">
            <b-row class="px-1 pb-1">
              <b-col
                cols="12"
                md="4"
                class="mb-md-0 mb-2"
              >
                <label class="saj-text">{{ $t("Jawatan") }}:</label>
                <b-form-select
                  v-model="role"
                  size="lg"
                  class="saj-text"
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="mb-md-0 mb-2"
              >
                <label class="saj-text">{{ $t("Carian Nama") }}:</label>
                <b-form-input
                  id="icons-search"
                  placeholder="Cari nama..."
                  size="lg"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card style="overflow-y: auto;">
          <b-table
            class="styleTable"
            style="font-size: 20px;"
            :items="settingList"
            :fields="fields"
            label-size="lg"
            show-empty
            bordered
          >
            <template #head()="data">
              <span
                class="d-flex justify-content-center"
                style="font-size: 20px;"
              >{{ data.label }}</span>
            </template>
            <template #cell(employee_number)="data">
              <div
                class="d-flex justify-content-center"
                style="
                              font-family: Poppins;
                              font-weight:400;
                              font-style: normal;
                              font-size: 20px;
                              color:#464E5F;
                              "
              >
                {{ data.item.employee_number }}
              </div>
            </template>

            <template #cell(employee_name)="data">
              <div
                class="d-flex justify-content-start"
                style="
                              font-family: Poppins;
                              font-weight:400;
                              font-style: normal;
                              font-size: 20px;
                              color:#464E5F;
                              "
              >
                {{ data.item.employee_name }}
              </div>
            </template>
          </b-table>
        </b-card>
      </b-container>
    </div>
  </div>
</template>
<script>
import {
// BAvatar,
  BTable,
  BCard,
  // BCardBody,
  BContainer,
  //   BImg,
  // BModal,
  BCol,
  BRow,
  BFormInput,
  //   BFormDatepicker,
  BFormSelect,
  //   BButton,
  // BCardHeader,

} from "bootstrap-vue"
// import axios from "axios"

export default {
  components: {
    // BAvatar,
    BCard,
    // BCardBody,
    BContainer,
    // BImg,
    // BModal,
    BCol,
    BRow,
    BFormInput,
    // BFormDatepicker,
    BFormSelect,
    // BButton,
    BTable,
    // BCardHeader,

  },

  data(){
    return {
      role: "",
      settingList: [

      ],
      fields: [
        {
          key: 'employee_number', label: 'No. Pekerja', thClass: 'text-center',
        },
        {
          key: 'employee_name', label: 'Nama Pekerja', tdClass: 'text-center',
        },
        {
          key: 'role', label: 'Jawatan', tdClass: 'text-center',
        },
        {
          key: 'part1', label: 'Bahagian 1', tdClass: 'text-center',
        },
        {
          key: 'part2', label: 'Bahagian 2', tdClass: 'text-center',
        },
        {
          key: 'part3', label: 'Bahagian 3', tdClass: 'text-center',
        },
        {
          key: 'allscore', label: 'Jumlah Keseluruhan (%)', tdClass: 'text-center',
        },
      ],
    }
  },
  mounted(){
  },
  methods: {
  },
}
</script>
<style>

</style>
